@import '../colors';

$bcHeight : 40px;
$bcLineHeight:30px;

.breadCrumb{

    height:$bcHeight; 
    width:100%;
     
     line-height: $bcLineHeight;
     vertical-align: middle;
     padding: ($bcHeight - $bcLineHeight)/2 0;
     .container{
        height:$bcLineHeight;
        padding-bottom:7px;
        border-bottom:solid 1px $color1;         
       // background:url(../img/border.png) bottom repeat-x;
     }
     
    ul{
        display:inline-block;
        padding-left:10px;
        margin:0; 
        li{
            display:inline-block; 
            position:relative;
            padding-right:10px;
            margin:0 0;

            &:after{
                padding-left: 10px;
                content:' » ';
            }
            &:last-child:after{
                content:initial;
                
            }

            a {
                color:inherit;
            }
        }
    }
}