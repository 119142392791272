@import '../_colors.scss';

.defaultDataselector {
    .tsSelector {
        & > div {
            font-size: 0.8em;
        }
    }




    .itemList {
        .title {
            padding-bottom: 10px;
            font-weight: bold;
        }


        .parType {
            font-weight: bold;
            border-bottom: solid 1px #eeeeee;
        }

        .item {
            text-overflow: ellipsis;
            white-space:nowrap;
            overflow:hidden;

            .itemContent {
                display: inline-block;
                width: 99%;

                .k-icon {
                    vertical-align: unset;
                }

                span {
                    width: 89%;
                    white-space: nowrap;
                    overflow: hidden;
                    display: inline-block;
                    text-overflow: ellipsis;
                }
            }

            &.unavailable {
                color:gray;
            }
        }
    }

    .LPWrap {
        font-size: 0.8em;
   
    }
}
