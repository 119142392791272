@import '../_colors.scss';

.selectedTimeline {
    .grdSelection {
        font-size: 0.8em;

        .vis-item.vis-range.percentageRangeItem {
            border-radius: 0px;
            border-style: none;
        }
    }
    .lblPeriod{
        font-size:0.8em;
    }
}
