//MVB Colors
@import 'colors';

.globalFooter {
    padding: 15px 0;

    .title {
        font-size: 19px;
        font-weight: 900;
    }

    .logo {
        &:before {
            content: '';
            display: block;
            position: absolute;
            left: 38px;
            top: -5px;
            height: 53px;
            width: 1px;
            transform: rotate(-20deg);
            transform-origin: top left;
            background-color: #cbd2da;
        }

        .logo_img {
            display: inline-block;

            svg {
                display: inline-block;
                vertical-align: middle;
                height: 40px;
                width: 25px;
                margin: 0;
                fill: $global_textColor;
            }
        }

        .logo_text {
            margin-left: 13px;
            display: inline-block;
            vertical-align: middle;
            line-height: 1.2;
            text-align: left;

            .logo_text_title {
                font-weight: bold;
                font-size: 18px;
            }

            .logo_text_tagline {
                font-size: 12px;
            }
        }
    }
}

.xsGlobalFooter {
    padding: 5px 0;

    .row {
        text-align: center;
        padding-bottom: 5px;
    }

    .title, .subTitle {
        font-size: 0.75em;
        font-weight: 500;
    }

    border-top: solid 1px $global_lineColor;

    .logo {
        &:before {
            content: '';
            display: inline-block;
            position: relative;
            left: 20px;
            top: 15px;
            height: 35px;
            width: 1px;
            transform: rotate(-20deg);
            transform-origin: top left;
            background-color: #cbd2da;
        }

        .logo_img {
            display: inline-block;

            svg {
                display: inline-block;
                vertical-align: middle;
                height: 30px;
                width: 19px;
                margin: 0;
                fill: $global_textColor;
            }
        }

        .logo_text {
            margin-left: 5px;
            display: inline-block;
            vertical-align: middle;
            line-height: 1;
            text-align: left;

            .logo_text_title {
                font-weight: bold;
                font-size: 14px;
            }

            .logo_text_tagline {
                font-size: 9px;
            }
        }
    }
}

.globalFooter, .xsGlobalFooter {
    background-color: $global_backColor;
    display: block;
    color: $global_textColor;
    font-size: 14px;
    margin-top: 10px;

    .footerNav {
        background-color: $color1;
        height: 31px;
        margin-bottom: 1rem;

        ul {
            li {
                display: inline-block;

                a {
                    line-height: 31px;
                    text-decoration: none;
                    padding: 0 10px;
                    color: white;
                }
            }

            &:first-of-type a {
                padding-left: 0;
            }
        }
    }

    .subtitle {
    }

    a {
        line-height: 21px;
        padding-top: 10px;
        padding-bottom: 10px;
        color: $linkColor;
        text-decoration: underline;
    }
}
