﻿
@import '../../colors';

body {
    overflow: scroll;
}

.container {
    width: auto;
    width: initial;
}




a {
    //color: inherit;
  
}


.fakelink {
    cursor: pointer;
    opacity: 0.8;

    &:hover {
        opacity: 1;
    }
}

@media (max-width: 576px) {
    .container.customBody {
        padding: 0;
        padding-top: 5px;
    }
}



.mvbdp3 {
    .errorNotification {

        .msgIcon {
            display: inline-block;
            width: 5%;
            font-size: 40px;
            min-width: 40px;
            vertical-align: top;
        }

        .msgText {
            display: inline-block;
            width: 90%;
        }
    }

    .k-widget.k-notification.k-notification-error {
        background-color: #d92800;
        color: white;
        border-color: #d92800;
        border-radius: 4px;
        padding: 20px 20px 5px 20px;
    }
}

.slanted{
    position:relative;
   // left:10px;
    display:inline-block;
    padding-left:17px;
    padding-right:20px;
    margin-left:8px;
    margin-right:-17px;
    transform:skewX(20deg);
    background-color:$color2;
    &>*{        transform:skewX(-20deg);    }
}

