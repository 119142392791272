.wizard {
    .window {
        position: relative;
        height: 99.9%;
        width: 99.9%;

        .contentWrapper {
            position: relative;
            display: inline-block;
            width: 99.9%;
            height: 100%;


            .content {
                .sel {
                }

                .sel:not(:last-child) {
                    border-bottom: 1px solid silver;
                    padding-bottom: 10px;
                    margin-bottom: 10px;
                }
            }

            .footer {
                //position: absolute;
                display: inline-block;
                width: 100%;
                //bottom: 0;
                text-align: right;
            }
        }
    }
}
