//MVB Colors
@import 'colors';

$headerHeight: 135px;
$logoHeight: 113px;
$logoWidth: 80px;

$titleHeight: 35px;
$languageHeight: 34px;

.customHeader {
    display: block;
    position: relative;
    color: $colorMDK;
    width: 100%;
    overflow: hidden;
    margin-right: 0;

    .bgLeft {
        display: block;
        z-index: 2;
        //min-width: 515px;
        .container {
            /*margin-left: auto;*/
            margin-right: 0;
            /*width: $leftContainerWidth;*/
            max-width: 100%;
        }
    }

    .bgRight {
        /*background: url(../img/afdelingkust-default-header-xl.jpg) right center;
        background-size: cover;
        z-index: 0;*/

        div.container {
            text-align: right;
            vertical-align: middle;
            // line-height: $logoHeight;
            height: $logoHeight;
            margin-left: 80px;
            margin-right: auto;
            margin-top: ($headerHeight - $logoHeight)/2;
            margin-bottom: ($headerHeight - $logoHeight)/2;
            width: 400px;
            max-width: 100%;
            z-index: 1000;
        }



        .menu {
            margin-top: 16px;
            color: $global_textColor;
            font-size: .9em;
            height: 40px;


            ul {
                display: inline-block;
                position: absolute;
                right: 0px;
                padding-right: 16px;
            }

            li {
                padding-left: 15px
            }

            a {
                color: $global_textColor;
            }
        }

        .menuA{
            .lang{
                &.selected{
                font-weight:700;
                }
                padding-left:5px;
                text-transform:uppercase;
            }

        }

        .menuB {
            font-weight:700;
            font-size:1.2em;
           li{
               padding-left:0px;
           }

            text-transform: uppercase;

            .kust {
                color: #C2B049;
            }

            .lood {
                color: #D26E25;
            }

            .sche {
                color: #4BCFA5;
            }

            .vloo {
                color: #16B8EE;
            }
        }
        /* .language {
            height: 100%;
            display: inline-block;
            height: $languageHeight;
            position: absolute;
            right: 0;



            select {
                background: rgba(255, 255, 255, 0.8);
                width: 160px;
                text-indent: 7px;
                border-color: #ccc;
                padding: .25em 0;
                color: #333;
            }
        }*/
    }

    .title {
        text-transform: uppercase;
        font-size: 30px;
        line-height: .85em;
        padding-top: 15px;
        /*   font-size: $titleHeight;
        font-weight: bold;
        min-width: 240px;
        height: $logoHeight;
        margin: ($headerHeight - $logoHeight)/2 0;
        padding: 16px 0 0 0;
        vertical-align: middle;
        line-height: 1.2em;*/
    }

    @mixin scaling($percentage) {
        min-height: $headerHeight *$percentage;

        > div {
            position: relative;
            min-height: $headerHeight*$percentage;
        }

        .title {
            /*  font-size: $titleHeight*$percentage;
            height: $logoHeight*$percentage;*/
        }

        .bgRight {
            .container {
                height: $logoHeight*$percentage;
            }

            .language {
                margin: (($headerHeight - $languageHeight)/2 * $percentage);
                margin-right: 15px;
            }
        }

        .logo {
            $logoMargin: (($headerHeight - $logoHeight)/2)*$percentage;
            margin: $logoMargin 0;
            max-width: 100%;
            white-space: nowrap;


            & > div {
                display: inline-block;
            }

            &:before {
                margin: $logoMargin 0;
                content: '';
                display: block;
                position: absolute;
                left: $logoWidth*$percentage;
                top: 0;
                height: ($logoHeight*1.1)*$percentage;
                width: 1px;
                transform: rotate(-20deg);
                transform-origin: top left;
                background-color: $color1;
            }

            .logo_img {
                display: inline-block;

                svg {
                    display: inline-block;
                    vertical-align: middle;
                    height: $logoHeight*$percentage;
                    width: $logoWidth*$percentage;
                    margin: 0;
                    fill: $color1;
                }
            }

            .logo_text {
                margin-left: 25px;
                display: inline-block;
                vertical-align: middle;
                line-height: 1.2;
                text-align: left;

                .logo_text_title {
                    font-weight: bold;
                    font-size: $logoHeight/3;
                }

                .logo_text_tagline {
                    font-size: $logoHeight/4;
                }
            }
        }
    }

    @include scaling(1);

    @media (max-width: 1100px) {
        @include scaling(0.9);
    }

    @media (max-width: 992px) {
        @include scaling(0.7);
    }

    @media (max-width: 768px) {
        @include scaling(0.5);
    }
    /*@media (max-width: 576px) {
        .title {
            font-size: $titleHeight*0.6;
        }
    }*/
}

.hippopotomonstrosesquippedalio{
    background-color:$color1;
    font-size:72px;
    padding:20px;
    margin-bottom:2px;
    color:white;

}