﻿@import '../colors';

.liveTile{
    margin-bottom:15px;
    .title{
        width:100%;
        background-color:$color1;
        text-transform:uppercase;
        font-size:1.2em;
        font-weight:bolder;
        color:white;
        padding:2px 12px;
        
    }

    .content{
        @media (max-width: 1100px) {
            font-size:0.8em;
        }
        border-left:1px solid silver;
        padding-left:5px;
        .Image{
            margin-left:-5px;
            img{
                width:100%;
            }
        }

        .Subsite{
            .groupTitle{
                font-weight:bolder;
            }

            .items{
                padding-left:10px;
                width:100%;

                .row{
                    padding-top:5px;

                    .lblTime{
                        color:silver;
                        margin-top:-6px;
                    }

                    .lblUnit{
                        display:inline-block;
                        width:35px;
                        padding-left:5px;


                    }
                }
                

               
            }
        }

        .News{
            .newsTitle{
                font-weight:bolder;
            }
            .newsSubTitle{
                color:silver;
                margin-top:-8px;
                padding-top:0;
            }
            .newsContent{
                padding-left:10px;
                padding-bottom:15px;
            }
            
        }


        .safeKitingItem{
            margin-bottom:5px;
            .groupTitle{
                font-weight:bolder;
            }

        }

    }


    
}
 