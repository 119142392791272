@import '../../_colors.scss';

//Kendo vs bootstrap fixes
.k-widget .row > div {
    box-sizing: border-box;
}

body > .moveBox {
    position: absolute;
    display: inline-block;
    background-color: silver;
    font-size: 0.8em;
}

.list-unstyled li .icon {
    padding-right: 3px;
}

.measurements_index {

    .k-tabstrip > .k-content {
        overflow: unset;
    }


    .views {
        padding-top: 10px;
    }

    .pull-right {
        float: right;
    }

    .settings {
        font-size: 0.8em;

        .k-notification {
            font-size: 1.1em;

            .timestamp {
                font-weight: 900;
            }
        }

        .title {
            font-weight: bold;
            font-size: 1.2em;
        }
    }

    .k-notification {
        font-size: 0.8em;

        .k-notification-wrap {
            padding: 1em;
        }
    }

    .parType {
        font-weight: bold;
        font-size: 0.8em;
    }

    .itemList, .moveBox {
        .title {
            font-weight: bold;
        }

        .item {
            font-size: 0.8em;
            white-space: nowrap;

            .k-icon {
                vertical-align: text-bottom;
            }
        }
    }

    .colorBox {
        display: inline-block;
        width: 7px;
        height: 7px;
        vertical-align: middle;
    }


    .views {
        position: relative;

        .message {
            z-index: 10000;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background-color: #303030;
            color: white;
            opacity: 0.5;
            padding-top: 30px;
            margin-top: 10px;
            text-align: center;
            vertical-align: central;
        }
    }

    .chart {
        height: 500px
    }

    .selectedLPs {
        padding-top: 5px;
    }

    .theSettingsBorder {
        border-top: 1px solid $global_lineColor;
    }

    .LPWrap {
        .remove {
            overflow: hidden;
        }
    }
}
