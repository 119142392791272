@import "../../_colors.scss";
//kendo fixes

.k-listview{
    overflow:auto;
    
}

.simpleView{
    .lstLoc .lstLocItem,
    .lstPar .lstParItem,
    .lstLocByPar .lstLocByParItem,
    .lstParByLoc .lstParByLocItem,
    .lstLPHist .lstLPHistoryItem {
            padding:1em;
            border-top:1px solid silver;
    }

    .lstLoc,
    .lstPar,
    .lstLocByPar,
    .lstParByLoc,
    .lstLPHist {
        border:none;
        border-bottom:1px solid silver;
    }

    .viewBy .title,.viewLPHist .title{ padding: 0.3em 1.0em; font-size:1.3em; font-weight:900}
    .viewBy .lpItem .big{ font-size:1.1em; font-weight:900}
    
    .k-tabstrip>.k-content{
        margin:0;
        padding:0;
        font-size:0.9em;
    }
    .k-tabstrip{
    font-size:0.9em;
    }

    

    
}


.xsHeader{
        background-color:white;
        color:$color1;
        position:relative;
        text-align:center;
        margin-bottom:5px;
        margin-top:0;
        
        span.logo_wrap{
            position:relative;
            margin-right:5px;
        }
         span.logo_img {

            &:before {
                margin: 0;
                content: '';
                display: inline-block;
                position: absolute;
                left: 20px;  
                top: -2px;
                height: 28px;
                width: 1px;
                opacity:0.7;
                transform: rotate(-20deg);
                transform-origin: top left;
                background-color: $color1;
            }

            svg {
                display: inline-block;
                vertical-align: middle;
                height: 24px;
                width: 24px;
                margin: 0;
                fill: $color1;
            }
        }
    }