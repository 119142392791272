﻿.map_index {
    .map {
        height: 400px;
        width: 100%;
    }


    .leaflet-tooltip.leaflet-tooltip-right{
        margin-left:10px !important;

    }

    .leaflet-tooltip.leaflet-tooltip-bottom{
        margin-top:10px !important;
    }
}


.leaflet-container{
     .leaflet-bar-settings{
         background-color:white;
        .divPanel{
            margin:10px;
        }
     }

}

/* Customize kendo multiselect*/
.k-list-container {
    .k-item {
        &.k-state-selected {
            background-color:transparent;

            .chk {
                color: inherit;
                visibility: visible;
            }
        }

        .chk {
            visibility: hidden;
            font-size:0.9em;
        }

        &:hover {
            background-color:#f2f2f2 !important;

            .chk {
                color: silver;
                visibility: visible;
            }

            &.k-state-selected .chk {
                color: inherit;
                visibility: visible;
            }
        }

        &.k-state-focused {
            border:0px !important;
        }
    }
}

#locationstring {
    opacity: 0.8;
    font-size: x-small;
}

#popupheader {
    margin-bottom: 2px;
}

.checkbox-parameters {
    margin-left: 2px;
    margin-right: 4px;
}

.leaflet-tooltip {
    position: absolute;
    padding: 0px !important;
    background-color: #fff;
    border: 0px solid #fff !important;
    border-radius: 3px;
    color: #222;
    white-space: nowrap;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    pointer-events: none;
    box-shadow: 0 1px 3px rgba(0,0,0,0.4);
}

#parametercontainer {
    margin-top: 10px;
    margin-bottom: 10px;
    //height:35px;
}

#parameterInfo {
    font-size: small;
}

.map-footer {
    font-size: small;
    padding-top: 5px;
}

.map-bookmark {
    cursor: pointer;
    padding-right: 5px;
    opacity: 0.8;

    &:hover {
        opacity: 1;
    }
}

.map-bookmark .fa-circle-o {
    padding-right: 2px;
}

.map-bookmarks {
    padding-left: 0px;
}

.map-currentdata-info {
    display: none;
    text-align: right;
    padding-right: 0px;
}


#parameters {
    display: none;
    margin-top: 4px;
}

$parametercolorlist: ((color0, rgba(244, 98, 66, 0.9)), (color1, rgba(244, 197, 66, 0.9)), (color2, rgba(223, 66, 244, 0.9)), (color3, rgba(75, 244, 66, 0.9)), (color4, rgba(66, 244, 220, 0.9)), (color5, rgba(66, 134, 244, 0.9)), (color6, rgba(142, 127, 127, 0.9)), (color7, rgba(126, 186, 174, 0.9)), (color8, rgba(133, 198, 115, 0.9)), (color9, rgba(255, 242, 0, 0.9)));

@each $colorname, $color in $parametercolorlist {
    .parameter#{$colorname} {
        &::before {
            color: $color;
            content: "\25cf";
            margin-right: 4px;
            display: inline-block;
        }
    }
}

@each $colorname, $color in $parametercolorlist {
    .label-checkbox.parameters.#{$colorname} {
        &::before {
            color: $color;
            content: "\25cf";
            padding: 4px;
            font-size: 25px;
        }
    }
}

@each $colorname, $color in $parametercolorlist {
    .leaflet-tooltip-parameter.#{$colorname} {
        background: $color;
        text-align: right;
        font-family: "Flanders";
        padding-left: 3px;
        padding-right: 3px;
    }
}
/*
 * These CSS rules affect the popups within maps with the custom-popup
*/
.custom-popup .leaflet-popup-content-wrapper {
    background: white;
    color: inherit;
    font-size: small;
    line-height: 24px;
    font-family: 'flanders';
    border-radius: 0px;
}

/* Popup settings */
.tsPopupContent {
    min-height:100px;

    span[class^="icon"] {
        margin-right:3px;
    }

    td.parname {
        text-align:right;
        padding-right:8px;
        padding-bottom:4px;
    }

    td.parvalue {
        text-align:left;
          .val{
              font-weight:900;
          }
    }

    
}

