
//GLOBAL
$global_backColor: #FFFFFF;
$global_textColor: #333332;
$global_lineColor: #E8EBEE;

$color1: #C2B049;
$color2: #EFEFEF;
$color3: #918337;
$colorMDK: #176e8b ;
$linkColor: rgb(0,102,204);

