﻿.mvbdp3 {
    .k-tabstrip-items{
        border-bottom:1px solid silver;

    }

    .k-button.k-state-focused, .k-button:focus {
        border-color: #eaeaea;
    }


    .fa.disabled,
    .fa[disabled],
    .disabled > .fa,
    [disabled] > .fa {
        //color: lightgrey;
        /*or*/ opacity: 0.5;
    }

    .errorMessage .k-notification-wrap {
        padding: 0.75em;
    }

    .k-textbox.state-success {
        border-color:forestgreen;
        &> .k-i-check {
            color: forestgreen;
        }
    }

    .k-textbox.state-error{
        border-color:indianred;

    }
}
