//MVB Colors
@import 'colors';

.globalHeader {
    background-color: $global_backColor;
    height: 44px;
    display: block;
    color: $global_textColor;
    font-size: 14px;
    border-bottom: solid 1px $global_lineColor;
    margin: 0;
    padding: 0;
    vertical-align: bottom;

    &:hover {
        color: #06c;
    }



    ul {
        margin: 0;

        li {
            &.topLevel {
                a {
                    font-size: 19px;
                    font-weight: bold;
                }
            }

            a {
                line-height: 44px;
                padding-top: 0px;
                padding-bottom: 0px;
                padding-left: 14px;
                padding-right: 14px;
                vertical-align: middle;
                color: $global_textColor;
                opacity: 1;

                &:hover {
                    text-decoration: none;
                    color: #06C;
                }

                &::after {
                    display: inline-block;
                    position: absolute;
                    content: ' ';
                    width: 1px;
                    right: 0;
                    top: 10px;
                    height: 25px;
                    /*top: 5px;
                    bottom: 5px;*/
                    background-color: $global_lineColor;
                }
            }

            &:first-of-type a {
                // padding-left: 0;

                &::after {
                    transform: rotate(-20deg);
                    transform-origin: top left;
                }
            }

            &:last-of-type a {
                &::after {
                    display: none;
                }
            }

            &.logo_img {
                display: inline-block;
                position: relative;
                left: -30px;
                padding-left: 30px;
                padding-top: 7px;
                padding-bottom: 8px;

                svg {
                    display: inline-block;
                    vertical-align: middle;
                    height: 30px;
                    width: 25px;
                    margin: 0;
                    fill: $global_textColor;
                }
            }
        }
    }

    .right {
        position: absolute;
        right: 10px;
        height: 44px;
        line-height: 44px;
        vertical-align: middle;
        color: white;
        cursor: pointer;

        & > div {
            display: inline-block;
        }

        a{
             text-transform:uppercase;

        }
    }
}














