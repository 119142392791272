
.big {
    font-size: 1.1em;
    font-weight: 900;
}

.pull-right::after{
    content:"";
    clear:both;

}

html {
    height: 100%;
    /*width:100%;
    overflow-x:hidden;*/
}
body {
    /*width:100%;
    overflow-x:hidden;*/
}
