//MVB Colors
@import 'colors';

$navHeight: 44px;
$ulHeight: 24px;

.mainNav {
    display: inline-block;
    width: 100%;
    height: $navHeight;
    background-color: $color1;
    position: relative;
    z-index: 800;

    ul {
        height: 100%;
        margin: 0;
        height: $ulHeight;
        margin: ($navHeight - $ulHeight)/2 0;

        li {
            display: inline-block;
            position: relative;
            vertical-align: top;

            a {
                line-height: 21px;
                padding: 11px 10px 11px 14px;
                vertical-align: middle;
                color: white;
                white-space: nowrap;
                font-weight: 200;

                &:hover, &.active {
                    background: $color3;
                    text-decoration: none;
                }

                &:focus {
                    text-decoration: none;
                }

                i {
                    font-size: 21px;
                }
            }

            &.has-dropdown {

                &:hover {
                    a {
                        background-color: white;
                        padding: 10px 10px 12px 14px;
                        color: $color3;
                        font-weight: 500;
                    }

                    .dropdown {
                        clip: auto;
                    }
                }

                .dropdown {
                    clip: rect(1px,1px,1px,1px);
                    width: auto;
                    left: 0;
                    min-width: 100%;
                    top: auto;
                    position: absolute;
                    padding-left: 0;
                    box-shadow: 0 3px 7px 0 rgba(0,0,0,.2);
                    height: auto;
                    background: #fff;


                    li {
                        position: relative;
                        //border: 1px solid #00ff21;
                        display: block;
                        height: 43px;
                        width: 100%;

                        &::after {
                            content: "";
                            border-bottom: 1px solid #959595;
                            display: block;
                            margin-left: 10px;
                            margin-right: 10px;
                        }

                        &:last-of-type {
                            &::after {
                                display: none;
                            }
                        }

                        a {
                            padding: 11px 14px 12px 14px;
                            color: #333;
                            display: inline-block;
                            width: 100%;
                            font-weight: 500;
                            background-color: transparent;

                            &:hover, &.active {
                                background-color:$color1;
                                color: white;
                            }
                        }
                    }
                }
            }
        }

        li.logo_img {
            margin-right: 4px;


            &:before {
                margin: 0;
                content: '';
                display: block;
                position: absolute;
                left: 20px;
                top: -3px;
                height: 32px;
                width: 1px;
                transform: rotate(-20deg);
                transform-origin: top left;
                background-color: white;
            }

            svg {
                display: inline-block;
                vertical-align: middle;
                height: 25px;
                width: 25px;
                margin: 0;
                fill: white;
            }
        }



        .open {
            .popup {
                visibility: visible
            }
        }

        .popup {
            &:before {
                content: "";
            }

            visibility: hidden;
            border: $color1 solid 1px;
            box-shadow: 2px 2px 2px 2px gray;
            max-width: 100%;
            margin-left: 17px;
            margin-right: -20px;





            ul {
                margin: 0;
                height: initial;

                li {
                    background-color: $color1;
                    min-width: 100%;


                    a {
                        display: inline-block;
                        min-width: 100%;
                    }
                }
            }
        }
    }

    .right {
        position: absolute;
        top: -10px;
        right: 10px;
        height: 44px;
        line-height: 44px;
        vertical-align: middle;
        color: white;
        cursor: pointer;

        & > div {
            display: inline-block;
        }
    }
}
