﻿   @font-face {
  font-family: 'flanders';
  font-style: normal;
  font-weight: 100;
  src: url("../fonts/FlandersArtSans/FlandersArtSans-light.eot");
  src: local("☺"), 
      url("../fonts/FlandersArtSans/FlandersArtSans-light.eot") format("embedded-opentype"), 
      url("../fonts/FlandersArtSans/FlandersArtSans-light.woff") format("woff"), 
      url("../fonts/FlandersArtSans/FlandersArtSans-light.ttf") format("truetype"), 
      url("../fonts/FlandersArtSans/FlandersArtSans-light.svg") format("svg"); 
 }

@font-face {
  font-family: 'flanders';
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/FlandersArtSans/FlandersArtSans-regular.eot");
  src: local("☺"),
      url("../fonts/FlandersArtSans/FlandersArtSans-regular.eot") format("embedded-opentype"),
      url("../fonts/FlandersArtSans/FlandersArtSans-regular.woff") format("woff"),
      url("../fonts/FlandersArtSans/FlandersArtSans-regular.ttf") format("truetype"), 
      url("../fonts/FlandersArtSans/FlandersArtSans-regular.svg") format("svg"); 

}

@font-face {
  font-family: 'flanders';
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/FlandersArtSans/FlandersArtSans-medium.eot");
  src: local("☺"), 
      url("../fonts/FlandersArtSans/FlandersArtSans-medium.eot") format("embedded-opentype"), 
      url("../fonts/FlandersArtSans/FlandersArtSans-medium.woff") format("woff"), 
      url("../fonts/FlandersArtSans/FlandersArtSans-medium.ttf") format("truetype"), 
      url("../fonts/FlandersArtSans/FlandersArtSans-medium.svg") format("svg"); 

}

@font-face {
  font-family: 'flanders';
  font-style: normal;
  font-weight: 900;
  src: url("../fonts/FlandersArtSans/FlandersArtSans-bold.eot");
  src: local("☺"), 
      url("../fonts/FlandersArtSans/FlandersArtSans-bold.eot") format("embedded-opentype"), 
      url("../fonts/FlandersArtSans/FlandersArtSans-bold.woff") format("woff"), 
      url("../fonts/FlandersArtSans/FlandersArtSans-bold.ttf") format("truetype"), 
      url("../fonts/FlandersArtSans/FlandersArtSans-bold.svg") format("svg"); }




html,body{
     font-family: flanders;
    -webkit-font-smoothing:antialiased !important;
}

