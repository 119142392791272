﻿.TimelineDescription {
    font-size: 0.78em;
}


.reference{
    .overview{
        border-top:1px solid silver;
        padding-top:10px;
        margin-top:10px;
         
    }

}