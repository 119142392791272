@import '../_colors.scss';

ul.list-pipe {
    li {
        display: inline-block;
        position: relative;

        a {
            padding-left: 14px;
            padding-right: 10px;
            color: black;
            opacity: 0.8;

            &:hover {
                opacity: 1;
                text-decoration: none;
            }

            &:active,&:focus {
                text-decoration: none;
            }

            &::before {
                display: inline-block;
                position: absolute;
                content: ' ';
                width: 1px;
                left: 0;
                top: 5px;
                bottom: 5px;
                background-color: $global_lineColor;
            }
        }

        &:first-of-type a {
            padding-left: 0;

            &::before {
                display: none;
            }
        }
    }
}
