.subsite_index {

    .dataSelector {
        font-size: 0.8em;

        .selectorItem {
            margin-bottom: 10px;
        }
    }

    .dataVisualizer {
        padding-top: 15px;
        border-top:solid 1px silver;
    }
}
